import { css } from '@emotion/react'
import React, { useState } from 'react'
import { Header } from '.'

export default function Protected({ location }) {
    const params = new URLSearchParams(location.search);
    const projectName = params.get("project");
    const [error, setError] = useState('');
    function onsubmit (e) {
        e.preventDefault();
        setTimeout(() => {
            setError(true);
        }, 300);
    }
    

  return (
    <div className='container'>
        <Header />
        <form onSubmit={onsubmit} className='df aic jcc fd-column' css={css`
            height: 100vh;
        `}>
            <svg style={{borderBlockEndColor: 'rgb(41, 42, 44)', borderBlockStartColor: 'rgb(41, 42, 44)', borderBottomColor: 'rgb(41, 42, 44)', borderInlineEndColor: 'rgb(41, 42, 44)', borderInlineStartColor: 'rgb(41, 42, 44)', borderLeftColor: 'rgb(41, 42, 44)', borderRightColor: 'rgb(41, 42, 44)', borderTopColor: 'rgb(41, 42, 44)', boxSizing: 'border-box', caretColor: 'rgb(41, 42, 44)', color: 'rgb(41, 42, 44)', columnRuleColor: 'rgb(41, 42, 44)', cursor: 'default', fill: 'rgb(147, 147, 147)', fontFamily: 'Open Sans, Arial, sans-serif', fontSize: '18px', lineHeight: '30.0001px', outlineColor: 'rgb(41, 42, 44)', overflowWrap: 'break-word', overflowX: 'hidden', overflowY: 'hidden', textAlign: 'center', textDecoration: 'none solid rgb(41, 42, 44)', textDecorationColor: 'rgb(41, 42, 44)', textEmphasisColor: 'rgb(41, 42, 44)', textSizeAdjust: '100%', WebkitFontSmoothing: 'antialiased', WebkitTextFillColor: 'rgb(41, 42, 44)', WebkitTextStrokeColor: 'rgb(41, 42, 44)', width: '35px', height: '52px'}} xmlns = {"http://www.w3.org/2000/svg"} width = {35} height = {52} viewBox = {"0 0 35 52"} >
            <path style={{borderBlockEndColor: 'rgb(41, 42, 44)', borderBlockStartColor: 'rgb(41, 42, 44)', borderBottomColor: 'rgb(41, 42, 44)', borderInlineEndColor: 'rgb(41, 42, 44)', borderInlineStartColor: 'rgb(41, 42, 44)', borderLeftColor: 'rgb(41, 42, 44)', borderRightColor: 'rgb(41, 42, 44)', borderTopColor: 'rgb(41, 42, 44)', boxSizing: 'border-box', caretColor: 'rgb(41, 42, 44)', color: 'rgb(41, 42, 44)', columnRuleColor: 'rgb(41, 42, 44)', cursor: 'default', fill: 'rgb(147, 147, 147)', fontFamily: 'Open Sans, Arial, sans-serif', fontSize: '18px', lineHeight: '30.0001px', outlineColor: 'rgb(41, 42, 44)', overflowWrap: 'break-word', textAlign: 'center', textDecoration: 'none solid rgb(41, 42, 44)', textDecorationColor: 'rgb(41, 42, 44)', textEmphasisColor: 'rgb(41, 42, 44)', textSizeAdjust: '100%', WebkitFontSmoothing: 'antialiased', WebkitTextFillColor: 'rgb(41, 42, 44)', WebkitTextStrokeColor: 'rgb(41, 42, 44)'}} id = {"Form_1"} data-name = {"Form 1"} d = {"M31.3,25.028H27.056a0.755,0.755,0,0,1-.752-0.757V14.654a8.8,8.8,0,1,0-17.608,0v9.616a0.755,0.755,0,0,1-.752.757H3.7a0.755,0.755,0,0,1-.752-0.757V14.654a14.556,14.556,0,1,1,29.111,0v9.616A0.755,0.755,0,0,1,31.3,25.028Zm-3.495-1.514h2.743V14.654a13.051,13.051,0,1,0-26.1,0v8.859H7.192V14.654a10.309,10.309,0,1,1,20.617,0v8.859Zm4.43,28.475H2.761A2.77,2.77,0,0,1,0,49.213V25.28a1.763,1.763,0,0,1,1.755-1.766H33.242A1.763,1.763,0,0,1,35,25.28V49.213A2.77,2.77,0,0,1,32.239,51.988ZM1.758,25.028a0.252,0.252,0,0,0-.251.252V49.213a1.259,1.259,0,0,0,1.254,1.262H32.239a1.259,1.259,0,0,0,1.254-1.262V25.28a0.252,0.252,0,0,0-.251-0.252H1.758ZM20.849,43h-6.7a0.75,0.75,0,0,1-.61-0.314,0.763,0.763,0,0,1-.1-0.682l1.471-4.44a4.1,4.1,0,1,1,5.184,0L21.563,42a0.763,0.763,0,0,1-.1.682A0.75,0.75,0,0,1,20.849,43ZM15.2,41.487H19.8l-1.319-3.979a0.76,0.76,0,0,1,.33-0.891,2.6,2.6,0,1,0-2.633,0,0.76,0.76,0,0,1,.33.891Z"} ></path>
            </svg>
            <h1 css={css`
                font-weight: 400;
                font-size: 50px;
                line-height: 44px;
                margin: 40px 0;
            `}>This content is protected.</h1>
            <sub css={css`
                line-height: 22px;
                font-size: 22px;
                font-weight: 400;
                color: #939393;
                margin-bottom: 50px;
            `}>To view <code>{projectName || ''}</code>, please enter the password.</sub>
            <input id='inputt' style={{appearance: 'none', borderBlockEndColor: 'rgb(0, 0, 0)', borderBlockEndStyle: 'none', borderBlockEndWidth: '0px', borderBlockStartColor: 'rgb(0, 0, 0)', borderBlockStartStyle: 'none', borderBlockStartWidth: '0px', borderBottomColor: 'rgb(0, 0, 0)', borderBottomLeftRadius: '2px', borderBottomRightRadius: '2px', borderBottomStyle: 'none', borderBottomWidth: '0px', borderInlineEndColor: 'rgb(0, 0, 0)', borderInlineEndStyle: 'none', borderInlineEndWidth: '0px', borderInlineStartColor: 'rgb(0, 0, 0)', borderInlineStartStyle: 'none', borderInlineStartWidth: '0px', borderLeftColor: 'rgb(0, 0, 0)', borderLeftStyle: 'none', borderLeftWidth: '0px', borderRightColor: 'rgb(0, 0, 0)', borderRightStyle: 'none', borderRightWidth: '0px', borderTopColor: 'rgb(0, 0, 0)', borderTopLeftRadius: '2px', borderTopRightRadius: '2px', borderTopStyle: 'none', borderTopWidth: '0px', boxShadow: 'rgba(0, 0, 0, 0.04) 10px 10px 30px 0px', boxSizing: 'border-box', fontSize: '22px', letterSpacing: '3.99996px', lineHeight: '59.9999px', overflowWrap: 'break-word', paddingBottom: '5.00004px', paddingLeft: '5.00004px', paddingRight: '5.00004px', paddingTop: '5.00004px', textAlign: 'left', textIndent: '14.9999px', textSizeAdjust: '100%', transitionDuration: '0.2s', transitionTimingFunction: 'linear', WebkitFontSmoothing: 'antialiased', width: '500px'}} className = {"post-password-input"} type = {"password"} size = {20} maxlength = {20} placeholder = {"Enter password"} 
                onChange={()=> setError()}
                css={css`
                border: 1px solid transparent;
                    border-color: ${error? 'red': '#888'} !important;
                `}
            />
            { error && <p css={css`
                color: #dd3322;
                margin-top: 20px;
            `}>The provided password is incorrect</p>}
            <button type='submit' style={{display: 'none'}}>
            </button>
            <label htmlFor='inputt'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                </svg>
            </label>
        </form>
    </div>
  )
}
